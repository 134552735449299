export default function getRoutesForm(db) {
    return [
        {
            title: ['Analysis Category', '›', 'New'],
            path: '/:db/administration/analysis-categories/new',
            saveRoute: '/api/analysis-categories',
            formRoute: '/api/analysis-categories/',
            isNew: true
        },
        {
            title: ['Analysis Category', '›', 'Edit'],
            path: '/:db/administration/analysis-categories/:id/edit',
            saveRoute: '/api/analysis-categories/',
            formRoute: '/api/analysis-categories/',
            isNew: true
        },

        {
            title: ['Analysis Code', '›', 'New'],
            path: '/:db/administration/analysis-codes/new',
            saveRoute: '/api/analysis-codes',
            formRoute: '/api/analysis-codes/',
            isNew: true
        },
        {
            title: ['Analysis Code', '›', 'Edit'],
            path: '/:db/administration/analysis-codes/:id/edit',
            saveRoute: '/api/analysis-codes/',
            formRoute: '/api/analysis-codes/',
            isNew: true
        },
        {
            title: ['Billing Data', '›', 'New'],
            path: '/:db/administration/billing-data/new',
            saveRoute: '/api/billing-data',
            formRoute: '/api/billing-datas/',
            isNew: true
        },
        {
            title: ['Billing Data', '›', 'Edit'],
            path: '/:db/administration/billing-data/:id/edit',
            saveRoute: '/api/billing-data/',
            formRoute: '/api/billing-datas/',
            updateOnSave: true,
            isNew: true
        },
        {
            title: ['Currencies', '›', 'New'],
            path: '/:db/administration/currencies/new',
            saveRoute: '/api/currencies',
            formRoute: '/api/currencies/',
            isNew: true
        },
        {
            title: ['Currencies', '›', 'Edit'],
            path: '/:db/administration/currencies/:id/edit',
            saveRoute: '/api/currencies/',
            formRoute: '/api/currencies/',
            isNew: true
        },
        {
            title: ['Currency rate', '›', 'New'],
            path: '/:db/administration/currency-rates/new',
            saveRoute: '/api/currency-rates',
            formRoute: '/api/currency-rates/',
            isNew: true
        },
        {
            title: ['Currency rate', '›', 'Edit'],
            path: '/:db/administration/currency-rates/:id/edit',
            saveRoute: '/api/currency-rates/',
            formRoute: '/api/currency-rates/',
            isNew: true
        },
        {
            title: ['Template', '›', 'New'],
            path: '/:db/administration/templates/document/new',
            saveRoute: '/api/document-templates',
            formRoute: '/api/document-templates/',
            isNew: true
        },
        {
            title: ['Template', '›', 'Edit'],
            path: '/:db/administration/templates/document/:id/edit',
            saveRoute: '/api/document-templates/',
            formRoute: '/api/document-templates/',
            isNew: true
        },
        {
            title: ['Email template', '›', 'New'],
            path: '/:db/administration/templates/email/new',
            saveRoute: '/api/email-templates',
            formRoute: '/api/email-templates/',
            isNew: true
        },
        {
            title: ['Email template', '›', 'Edit'],
            path: '/:db/administration/templates/email/:id/edit',
            saveRoute: '/api/email-templates/',
            formRoute: '/api/email-templates/',
            isNew: true
        },
        {
            title: ['SMS template', '›', 'New'],
            path: '/:db/administration/templates/sms/new',
            saveRoute: '/api/sms-templates',
            formRoute: '/api/sms-templates/',
            isNew: true
        },
        {
            title: ['SMS template', '›', 'Edit'],
            path: '/:db/administration/templates/sms/:id/edit',
            saveRoute: '/api/sms-templates/',
            formRoute: '/api/sms-templates/',
            isNew: true
        },
        {
            title: ['Export template', '›', 'New'],
            path: '/:db/administration/templates/export/new',
            saveRoute: '/api/export-templates',
            formRoute: '/api/export-templates/',
            isNew: true
        },
        {
            title: ['Export template', '›', 'Edit'],
            path: '/:db/administration/templates/export/:id/edit',
            saveRoute: '/api/export-templates/',
            formRoute: '/api/export-templates/',
            isNew: true
        },
        {
            title: ['Activity Type', '›', 'New'],
            path: '/:db/administration/events/new',
            saveRoute: '/api/events',
            formRoute: '/api/events/',
            isNew: true
        },
        {
            title: ['Activity Type', '›', 'Edit'],
            path: '/:db/administration/events/:id/edit',
            saveRoute: '/api/events/',
            formRoute: '/api/events/',
            isNew: true
        },
        {
            title: ['Language', '›', 'New'],
            path: '/:db/administration/languages/new',
            saveRoute: '/api/languages',
            formRoute: '/api/languages/',
            isNew: true
        },
        {
            title: ['Language', '›', 'Edit'],
            path: '/:db/administration/languages/:id/edit',
            saveRoute: '/api/languages/',
            formRoute: '/api/languages/',
            isNew: true
        },
        {
            title: ['Location', '›', 'New'],
            path: '/:db/administration/locations/new',
            saveRoute: '/api/locations',
            formRoute: '/api/locations/',
            updateOnSave: true,
            isNew: true
        },
        {
            title: ['Location', '›', 'Edit'],
            path: '/:db/administration/locations/:id/edit',
            saveRoute: '/api/locations/',
            formRoute: '/api/locations/',
            isNew: true
        },
        {
            title: ['Measure', '›', 'New'],
            path: '/:db/administration/measure/new',
            saveRoute: '/api/measures',
            formRoute: '/api/measures/',
            isNew: true
        },
        {
            title: ['Measure', '›', 'Edit'],
            path: '/:db/administration/measure/:id/edit',
            saveRoute: '/api/measures/',
            formRoute: '/api/measures/',
            isNew: true
        },
        {
            title: ['Measure conversion', '›', 'New'],
            path: '/:db/administration/measure-conversion/new',
            saveRoute: '/api/measure-conversions',
            formRoute: '/api/measure-conversions/',
            isNew: true
        },
        {
            title: ['Measure conversion', '›', 'Edit'],
            path: '/:db/administration/measure-conversion/:id/edit',
            saveRoute: '/api/measure-conversions/',
            formRoute: '/api/measure-conversions/',
            isNew: true
        },
        {
            title: ['Payment method', '›', 'New'],
            path: '/:db/administration/payment-methods/new',
            saveRoute: '/api/payment-methods',
            formRoute: '/api/payment-methods/',
            isNew: true
        },
        {
            title: ['Payment method', '›', 'Edit'],
            path: '/:db/administration/payment-methods/:id/edit',
            saveRoute: '/api/payment-methods/',
            formRoute: '/api/payment-methods/',
            isNew: true
        },
        {
            title: ['Translation', '›', 'New'],
            path: '/:db/administration/translations/new',
            saveRoute: '/api/translations',
            formRoute: '/api/translations/',
            isNew: true
        },
        {
            title: ['Translation', '›', 'Edit'],
            path: '/:db/administration/translations/:id/edit',
            saveRoute: '/api/translations/',
            formRoute: '/api/translations/',
            isNew: true
        },
        {
            title: ['Tax', '›', 'New'],
            path: '/:db/administration/taxes/new',
            saveRoute: '/api/taxes',
            formRoute: '/api/taxes/',
            isNew: true
        },
        {
            title: ['Tax', '›', 'Edit'],
            path: '/:db/administration/taxes/:id/edit',
            saveRoute: '/api/taxes/',
            formRoute: '/api/taxes/',
            isNew: true
        },
        {
            title: ['Tax class', '›', 'New'],
            path: '/:db/administration/taxes-class/new',
            saveRoute: '/api/taxes-class',
            formRoute: '/api/taxes-class/',
            isNew: true
        },
        {
            title: ['Tax class', '›', 'Edit'],
            path: '/:db/administration/taxes-class/:id/edit',
            saveRoute: '/api/taxes-class/',
            formRoute: '/api/taxes-class/',
            isNew: true
        },
        {
            title: ['Text', '›', 'New'],
            path: '/:db/administration/texts/new',
            saveRoute: '/api/texts',
            formRoute: '/api/texts/',
            isNew: true
        },
        {
            title: ['Text', '›', 'Edit'],
            path: '/:db/administration/texts/:id/edit',
            saveRoute: '/api/texts/',
            formRoute: '/api/texts/',
            isNew: true
        },
        {
            title: ['Project', '›', 'New'],
            path: '/:db/project/new',
            saveRoute: '/api/projects',
            formRoute: '/api/projects/',
            isNew: true
        },
        {
            title: ['Project', '›', 'Edit'],
            path: '/:db/project/:id/edit',
            saveRoute: '/api/projects/',
            formRoute: '/api/projects/',
            isNew: true
        },
        {
            title: ['Task', '›', 'New'],
            path: '/:db/task/new',
            saveRoute: '/api/tasks',
            formRoute: '/api/tasks/',
            isNew: true
        },
        {
            title: ['Task', '›', 'Edit'],
            path: '/:db/task/:id/edit',
            saveRoute: '/api/tasks/',
            formRoute: '/api/tasks/',
            isNew: true
        },
        {
            title: ['Notification', '›', 'New'],
            path: '/:db/notification/new',
            saveRoute: '/api/notifications/new',
            formRoute: '/api/notification/form/'
        },
        {
            title: ['Notification', '›', 'Edit'],
            path: '/:db/notification/:id/edit',
            saveRoute: '/api/notifications/edit',
            formRoute: '/api/notification/form/'
        },
        {
            title: ['Role', '›', 'New'],
            path: '/:db/settings/accounts/roles/new',
            saveRoute: '/api/roles',
            formRoute: '/api/roles/',
            isNew: true
        },
        {
            title: ['Role', '›', 'Edit'],
            path: '/:db/settings/accounts/roles/:id/edit',
            saveRoute: '/api/roles/edit',
            formRoute: '/api/role/form/'
        },
        {
            title: ['Department', '›' ,'New'],
            path: '/:db/hr/departments/new',
            saveRoute: '/api/departments',
            formRoute: '/api/departments/',
            isNew: true
        },
        {
            title: ['Department', '›' ,'Edit'],
            path: '/:db/hr/departments/:id/edit',
            saveRoute: '/api/departments/',
            formRoute: '/api/departments/',
            isNew: true
        },
        {
            title: ['Employee', '›' ,'New'],
            path: '/:db/hr/employees/new',
            saveRoute: '/api/employees',
            formRoute: '/api/employees/',
            isNew: true
        },
        {
            title: ['Employee', '›' ,'Edit'],
            path: '/:db/hr/employees/:id/edit',
            saveRoute: '/api/employees/',
            formRoute: '/api/employees/',
            isNew: true
        },
        {
            title: ['Account', '›' ,'New'],
            path: '/:db/account/form/:id/new',
            saveRoute: '/api/accounts/new',
            formRoute: '/api/account/form/',
            isAdditionalId: true
        },       
        {
            title: ['Payments Report'],
            path: '/:db/report/payments/filters',
            saveRoute: '/api/reports/payment/dataSource',
            formRoute: '/api/reports/payment/filters/',
            isNew: true,
            applyButton: true
        },
        {
            title: ['Report', '›' ,'New'],
            path: '/:db/reports/new',
            saveRoute: '/api/reports',
            formRoute: '/api/reports/',
            isNew: true
        },

        {
            title: ['Report', '›' ,'Edit'],
            path: '/:db/reports/:id/edit',
            saveRoute: '/api/reports/',
            formRoute: '/api/reports/',
            isNew: true
        },
        {
            title: ['User Roles', '›' ,'New'],
            path: '/:db/settings/roles/new',
            saveRoute: '/api/user-roles',
            formRoute: '/api/user-roles/',
            isNew: true,
            isOldForm: true
        },
        {
            title: ['User Roles', '›' ,'Edit'],
            path: '/:db/settings/roles/:id/edit',
            saveRoute: '/api/user-roles/',
            formRoute: '/api/user-roles/',
            isNew: true,
            isOldForm: true
        },
        {
            title: ['Item Categories', '›' ,'New'],
            path: '/:db/administration/items/categories/new',
            saveRoute: '/api/item-categories',
            formRoute: '/api/item-categories/',
            isNew: true
        },
        {
            title: ['Item Categories', '›' ,'Edit'],
            path: '/:db/administration/items/categories/:id/edit',
            saveRoute: '/api/item-categories/',
            formRoute: '/api/item-categories/',
            isNew: true
        },
        {
            title: ['Users', '›' ,'New'],
            path: '/:db/administration/user/new',
            saveRoute: '/api/users',
            formRoute: '/api/users/',
            isNew: true
        },
        {
            title: ['Users', '›' ,'Edit'],
            path: '/:db/administration/user/:id/edit',
            saveRoute: '/api/users/',
            formRoute: '/api/users/',
            isNew: true
        },
        {
            title: ['Item', '›' ,'New'],
            path: '/:db/administration/items/new',
            saveRoute: '/api/items',
            formRoute: '/api/items/',
            isNew: true
        },
        {
            title: ['Items', '›' ,'Edit'],
            path: '/:db/administration/items/:id/edit',
            saveRoute: '/api/items/',
            formRoute: '/api/items/',
            isNew: true
        },
        {
            title: ['Items', '›' ,'Edit'],
            path: '/:db/administration/items/:id/bill-of-materials',
            saveRoute: '/api/items/bill-of-materials/',
            formRoute: '/api/items/bill-of-materials/',
            isNew: true
        },
//        {
//            title: ['Invoice', '›' ,'New'],
//            path: '/:db/invoice/new',
//            saveRoute: '/api/invoices',
//            formRoute: '/api/invoices/',
//            isNew: true,
//            issueButton: true
//        },
        {
            title: ['Invoice', '›' ,'Edit'],
            path: '/:db/invoice/:id/edit',
            saveRoute: '/api/invoices/',
            formRoute: '/api/invoices/',
            isNew: true,
            notSaveButton: false
        },
        {
            title: ['Expense category', '›' ,'New'],
            path: '/:db/expense-categories/new',
            saveRoute: '/api/expense-categories',
            formRoute: '/api/expense-categories/',
            isNew: true
        },
        {
            title: ['Expense category', '›' ,'Edit'],
            path: '/:db/expense-categories/:id/edit',
            saveRoute: '/api/expense-categories/',
            formRoute: '/api/expense-categories/',
            isNew: true
        },
        {
            title: ['Expenses', '›' ,'New'],
            path: '/:db/expenses/new',
            saveRoute: '/api/expenses',
            formRoute: '/api/expenses/',
            isNew: true
        },
        {
            title: ['Expenses', '›' ,'Edit'],
            path: '/:db/expenses/:id/edit',
            saveRoute: '/api/expenses/',
            formRoute: '/api/expenses/',
            isNew: true
        },
        {
            title: ['Form type', '›' ,'New'],
            path: '/:db/form-types/new',
            saveRoute: '/api/form-types',
            formRoute: '/api/form-types/',
            isNew: true
        },
        {
            title: ['Form type', '›' ,'Edit'],
            path: '/:db/form-types/:id/edit',
            saveRoute: '/api/form-types/',
            formRoute: '/api/form-types/',
            isNew: true
        },
        {
            title: ['Pricelist', '›' ,'New'],
            path: '/:db/administration/pricelist/new',
            saveRoute: '/api/pricelist',
            formRoute: '/api/pricelist/',
            isNew: true
        },
        {
            title: ['Pricelist', '›' ,'Edit'],
            path: '/:db/administration/pricelist/:id/edit',
            saveRoute: '/api/pricelist/',
            formRoute: '/api/pricelist/',
            isNew: true
        },
        {
            title: ['Discount', '›' ,'New'],
            path: '/:db/administration/discount/new',
            saveRoute: '/api/discount',
            formRoute: '/api/discount/',
            isNew: true
        },
        {
            title: ['Discount', '›' ,'Edit'],
            path: '/:db/administration/discount/:id/edit',
            saveRoute: '/api/discount/',
            formRoute: '/api/discount/',
            isNew: true
        },
        {
            title: ['Receipt', '›' ,'New'],
            path: '/:db/receipt/new',
            saveRoute: '/api/inventories',
            formRoute: '/api/inventories/',
            isNew: true,
            download :  '/inventories/receipts/'
        },
        {
            title: ['Receipt', '›' ,'Edit'],
            path: '/:db/receipt/:id/edit',
            saveRoute: '/api/inventories/',
            formRoute: '/api/inventories/',
            isNew: true,
            download :  '/inventories/receipts/'
        },
        {
            title: ['Translate', '›', 'Edit'],
            path: '/:db/administration/translations/:id/translate',
            saveRoute: '/api/translates/',
            formRoute: '/api/translates/',
            isNew: true
        },
        {
            title: ['Payment', '›' ,'New'],
            path: '/:db/payment/new',
            saveRoute: '/api/payments',
            formRoute: '/api/payments/',
            isNew: true,
        },
        {
            title: ['Payment', '›' ,'Edit'],
            path: '/:db/payment/:id/edit',
            saveRoute: '/api/payments/',
            formRoute: '/api/payments/',
            isNew: true,
        },
        {
            title: ['Sale', '›' ,'New'],
            path: '/:db/sales/new',
            saveRoute: '/api/sales',
            formRoute: '/api/sales/',
            isNew: true,
            checkDevices: '/api/devices-search'
        },
        {
            title: ['Sale', '›' ,'Edit'],
            path: '/:db/sales/:id/edit',
            saveRoute: '/api/sales/',
            formRoute: '/api/sales/',
            isNew: true,
            updateOnSave: true,
            checkDevices: '/api/devices-search'
        },
        {
            title: ['Device', '›', 'New'],
            path: '/:db/administration/devices/new',
            saveRoute: '/api/devices',
            formRoute: '/api/devices/',
            isNew: true
        },
        {
            title: ['Device', '›', 'Edit'],
            path: '/:db/administration/devices/:id/edit',
            saveRoute: '/api/devices/',
            formRoute: '/api/devices/',
            isNew: true
        },
        {
            title: ['Upload', '›' ,'New'],
            path: '/:db/import/upload/file',
            saveRoute: '/api/import/upload/file',
            formRoute: '/api/import/upload/',
            isNew: true
        },
        {
            title: ['Batch', '›' ,'New'],
            path: '/:db/administration/batch/new',
            saveRoute: '/api/batches',
            formRoute: '/api/batches/',
            isNew: true,
        },
        {
            title: ['Batch', '›' ,'Edit'],
            path: '/:db/administration/batch/:id/edit',
            saveRoute: '/api/batches/',
            formRoute: '/api/batches/',
            isNew: true,
        },
        {
            title: ['Group', '›', 'New'],
            path: '/:db/administration/groups/new',
            saveRoute: '/api/groups',
            formRoute: '/api/groups/',
            isNew: true
        },
        {
            title: ['Group', '›', 'Edit'],
            path: '/:db/administration/groups/:id/edit',
            saveRoute: '/api/groups/',
            formRoute: '/api/groups/',
            isNew: true
        },
        {
            title: ['Make payment', '›' ,'New'],
            path: '/:db/sale/:id/make-payment/new',
            saveRoute: '/api/make-payments',
            formRoute: '/api/make-payments/',
            customForm: 'payment',
            isNew: true,
            checkDevices: '/api/devices-search',
            printReceipt: true
        },
        {
            title: ['Location Workplace', '›', 'New'],
            path: '/:db/administration/workplace/new',
            saveRoute: '/api/administration/workplace',
            formRoute: '/api/administration/workplace/',
            isNew: true
        },
        {
            title: ['Location Workplace', '›', 'Edit'],
            path: '/:db/administration/workplace/:id/edit',
            saveRoute: '/api/administration/workplace/',
            formRoute: '/api/administration/workplace/',
            isNew: true
        },
        {
            title: ['Delivery', '›' ,'New'],
            path: '/:db/delivery/new',
            saveRoute: '/api/deliveries',
            formRoute: '/api/deliveries/',
            isNew: true,
            updateOnSave: true,
            cancelButton: '/:db/sales',
        },
        {
            title: ['Delivery', '›' ,'Edit'],
            path: '/:db/delivery/:id/edit',
            saveRoute: '/api/deliveries/',
            formRoute: '/api/deliveries/',
            isNew: true,
        },
        {
            title: ['Document Sequence', '›', 'New'],
            path: '/:db/administration/document-sequences/new',
            saveRoute: '/api/document-sequences',
            formRoute: '/api/document-sequences/',
            isNew: true
        },
        {
            title: ['Document Sequence', '›', 'Edit'],
            path: '/:db/administration/document-sequences/:id/edit',
            saveRoute: '/api/document-sequences/',
            formRoute: '/api/document-sequences/',
            isNew: true
        },
        {
            title: ['Content data', '›', 'New'],
            path: '/:db/content-data/:id/new',
            saveRoute: '/api/content-data/',
            formRoute: '/api/content-data/',
            isNew: true
        },
        {
            title: ['Content data', '›', 'Edit'],
            path: '/:db/content-data/:id/edit',
            saveRoute: '/api/content-data/edit/',
            formRoute: '/api/content-data/',
            isNew: true
        },
        {
            title: ['Content type', '›', 'New'],
            path: '/:db/content-types/new',
            saveRoute: '/api/content-types',
            formRoute: '/api/content-types/',
            isNew: true
        },
        {
            title: ['Content type', '›', 'Edit'],
            path: '/:db/content-types/:id/edit',
            saveRoute: '/api/content-types/',
            formRoute: '/api/content-types/',
            isNew: true
        },
        {
            title: ['Content category', '›', 'New'],
            path: '/:db/content-categories/new',
            saveRoute: '/api/content-categories',
            formRoute: '/api/content-categories/',
            isNew: true
        },
        {
            title: ['Content category', '›', 'Edit'],
            path: '/:db/content-categories/:id/edit',
            saveRoute: '/api/content-categories/',
            formRoute: '/api/content-categories/',
            isNew: true
        },
        {
            title: ['Chart of accounts', '›', 'New'],
            path: '/:db/accounting/chart-of-accounts/new',
            saveRoute: '/api/chart-of-accounts',
            formRoute: '/api/chart-of-accounts/',
            isNew: true,
        },
        {
            title: ['Chart of accounts', '›', 'Edit'],
            path: '/:db/accounting/chart-of-accounts/:id/edit',
            saveRoute: '/api/chart-of-accounts/',
            formRoute: '/api/chart-of-accounts/',
            isNew: true,
        },
        {
            title: ['Journals', '›', 'New'],
            path: '/:db/accounting/journal-types/new',
            saveRoute: '/api/journals',
            formRoute: '/api/journals/',
            isNew: true
        },
        {
            title: ['Journals', '›', 'Edit'],
            path: '/:db/accounting/journal-types/:id/edit',
            saveRoute: '/api/journals/',
            formRoute: '/api/journals/',
            isNew: true
        },
        {
            title: ['Ledger', '›', 'New'],
            path: '/:db/accountings/new',
            saveRoute: '/api/new-ledger',
            formRoute: '/api/new-ledger/',
            isNew: true
        },
        {
            title: ['Ledger', '›', 'Edit'],
            path: '/:db/accountings/:id/edit',
            saveRoute: '/api/new-ledger/',
            formRoute: '/api/new-ledger/',
            isNew: true
        },
        {
            title: ['Report Settings', '›', 'Edit'],
            path: '/:db/report-definition/:id/edit',
            saveRoute: '/api/report-definition/',
            formRoute: '/api/report-definition/',
            isNew: true
        },
        {
            title: ['Content attribute', '›', 'New'],
            path: '/:db/content-attributes/new',
            saveRoute: '/api/content-attributes',
            formRoute: '/api/content-attributes/',
            isNew: true
        },
        {
            title: ['Content category', '›', 'Edit'],
            path: '/:db/content-attributes/:id/edit',
            saveRoute: '/api/content-attributes/',
            formRoute: '/api/content-attributes/',
            isNew: true
        },
        {
            title: ['Channel', '›', 'New'],
            path: '/:db/administration/channels/new',
            saveRoute: '/api/channels',
            formRoute: '/api/channels/',
            isNew: true
        },
        {
            title: ['Channel', '›', 'Edit'],
            path: '/:db/administration/channels/:id/edit',
            saveRoute: '/api/channels/',
            formRoute: '/api/channels/',
            isNew: true
        },
        {
            title: ['Production', '›', 'New'],
            path: '/:db/production/new',
            saveRoute: '/api/production',
            formRoute: '/api/production/',
            isNew: true
        },
        {
            title: ['Production', '›', 'Edit'],
            path: '/:db/production/:id/edit',
            saveRoute: '/api/production/',
            formRoute: '/api/production/',
            isNew: true,
            updateOnSave: true,
        },
        {
            title: ['List', '›', 'New'],
            path: '/:db/lists/new',
            saveRoute: '/api/lists',
            formRoute: '/api/lists/',
            isNew: true
        },
        {
            title: ['List', '›', 'Edit'],
            path: '/:db/lists/:id/edit',
            saveRoute: '/api/lists/',
            formRoute: '/api/lists/',
            isNew: true
        },
        {
            title: ['Deal', '›' ,'New'],
            path: '/:db/deals/new',
            saveRoute: '/api/deals',
            formRoute: '/api/deals/',
            isNew: true,
        },
        {
            title: ['Deal', '›' ,'Edit'],
            path: '/:db/deals/:id/edit',
            saveRoute: '/api/deals/',
            formRoute: '/api/deals/',
            isNew: true,
        },
        {
            title: ['Logo'],
            path: '/:db/administration/company-logo',
            saveRoute: '/api/company-logo/save/settings/individual',
            formRoute: '/api/company-logo/',
            isNew: true,
        },
        {
            title: ['Log Time', '›' ,'Edit'],
            path: '/:db/log-time/:id/edit',
            saveRoute: '/api/log-time/',
            formRoute: '/api/log-time/',
            isNew: true,
        },
        {
            title: ['Item request', '›' ,'New'],
            path: '/:db/item-requests/single/new',
            saveRoute: '/api/item-requests',
            formRoute: '/api/item-requests/',
            isNew: true
        },
        {
            title: ['Item requests', '›' ,'New'],
            path: '/:db/item-requests/new',
            saveRoute: '/api/item-request/multiple',
            formRoute: '/api/item-requests/',
            isNew: true
        },
        {
            title: ['Item requests', '›' ,'Edit'],
            path: '/:db/item-requests/:id/edit',
            saveRoute: '/api/item-request/multiple/',
            formRoute: '/api/item-requests/',
            isNew: true,
        },
        {
            title: ['Incoterm', '›', 'New'],
            path: '/:db/administration/incoterms/new',
            saveRoute: '/api/incoterms',
            formRoute: '/api/incoterms/',
            isNew: true
        },
        {
            title: ['Incoterm', '›', 'Edit'],
            path: '/:db/administration/incoterms/:id/edit',
            saveRoute: '/api/incoterms/',
            formRoute: '/api/incoterms/',
            isNew: true
        },
        {
            title: ['Setup', '›', 'New'],
            path: '/:db/administration/setup/new',
            saveRoute: '/api/administration/setup',
            formRoute: '/api/administration/setup/',
            isNew: true
        },
        {
            title: ['Setup', '›', 'Edit'],
            path: '/:db/administration/setup/:id/edit',
            saveRoute: '/api/administration/setup/',
            formRoute: '/api/administration/setup/',
            isNew: true
        },
        {
            title: ['Calendar event type', '›', 'New'],
            path: '/:db/administration/calendar-type/new',
            saveRoute: '/api/calendar-type',
            formRoute: '/api/calendar-type/',
            isNew: true
        },
        {
            title: ['Calendar event type', '›', 'Edit'],
            path: '/:db/administration/calendar-type/:id/edit',
            saveRoute: '/api/calendar-type/',
            formRoute: '/api/calendar-type/',
            updateOnSave: true,
            isNew: true
        },
        {
            title: ['Calendar', '›', 'New'],
            path: '/:db/administration/calendars/new',
            saveRoute: '/api/calendars',
            formRoute: '/api/calendars/',
            isNew: true
        },
        {
            title: ['Calendar', '›', 'Edit'],
            path: '/:db/administration/calendars/:id/edit',
            saveRoute: '/api/calendars/',
            formRoute: '/api/calendars/',
            isNew: true
        },
        {
            title: ['Calendar Event', '›', 'New'],
            path: '/:db/administration/calendar-event/new',
            saveRoute: '/api/calendar-event',
            formRoute: '/api/calendar-event/',
            isNew: true
        },
        {
            title: ['Calendar Event', '›', 'Edit'],
            path: '/:db/administration/calendar-event/:id/edit',
            saveRoute: '/api/calendar-event/',
            formRoute: '/api/calendar-event/',
            isNew: true
        },
        {    
            title: ['Product', '›', 'New'],
            path: '/:db/products/new',
            saveRoute: '/api/products',
            formRoute: '/api/products/',
            isNew: true
        },
        {
            title: ['Product', '›', 'Edit'],
            path: '/:db/products/:id/edit',
            saveRoute: '/api/products/',
            formRoute: '/api/products/',
            isNew: true
        },
        {
            title: ['Survey', '›', 'New'],
            path: '/:db/surveys/new',
            saveRoute: '/api/surveys',
            formRoute: '/api/surveys/',
            isNew: true
        },
        {
            title: ['Survey', '›', 'Edit'],
            path: '/:db/surveys/:id/edit',
            saveRoute: '/api/surveys/',
            formRoute: '/api/surveys/',
            isNew: true
        },
        {
            title: ['Account segment', '›', 'New'],
            path: '/:db/segments/new',
            saveRoute: '/api/segments',
            formRoute: '/api/segments/',
            isNew: true
        },
        {
            title: ['Account segment', '›', 'Edit'],
            path: '/:db/segments/:id/edit',
            saveRoute: '/api/segments/',
            formRoute: '/api/segments/',
            isNew: true
        },
        {
            title: ['Settings integration', '›', 'New'],
            path: '/:db/settings/integration/new',
            saveRoute: '/api/integration',
            formRoute: '/api/integration/',
            isNew: true
        },
        {
            title: ['Settings integration', '›', 'Edit'],
            path: '/:db/settings/integration/:id/edit',
            saveRoute: '/api/integration/',
            formRoute: '/api/integration/',
            isNew: true
        },
        {
            title: ['Email message', '›', 'New'],
            path: '/:db/message-email/new',
            saveRoute: '/api/message-email/new',
            formRoute: '/api/message-email/',
            isNew: true, 
            sendButton: true
        },
        {
            title: ['Location Place', '›', 'New'],
            path: '/:db/location-places/new',
            saveRoute: '/api/location-places',
            formRoute: '/api/location-places/',
            isNew: true
        },
        {
            title: ['Location Place', '›', 'Edit'],
            path: '/:db/location-places/:id/edit',
            saveRoute: '/api/location-places/',
            formRoute: '/api/location-places/',
            isNew: true
        },
        {
            title: ['Inventory Place', '›', 'Edit'],
            path: '/:db/inventory-places/:id/edit',
            saveRoute: '/api/inventory-places/',
            formRoute: '/api/inventory-places/',
            isNew: true
        },
        {
            title: ['Asset', '›', 'New'],
            path: '/:db/assets/new',
            saveRoute: '/api/assets',
            formRoute: '/api/assets/',
            isNew: true
        },
        {
            title: ['Asset', '›', 'Edit'],
            path: '/:db/assets/:id/edit',
            saveRoute: '/api/assets/',
            formRoute: '/api/assets/',
            isNew: true
        },
        {
            title: ['Delivery Method', '›', 'New'],
            path: '/:db/administration/delivery-methods/new',
            saveRoute: '/api/delivery-method',
            formRoute: '/api/delivery-method/',
            isNew: true
        },
        {
            title: ['Delivery Method', '›', 'Edit'],
            path: '/:db/administration/delivery-methods/:id/edit',
            saveRoute: '/api/delivery-method/',
            formRoute: '/api/delivery-method/',
            isNew: true
        },
        {
            title: ['Address', '›', 'New'],
            path: '/:db/administration/address/new',
            saveRoute: '/api/address',
            formRoute: '/api/address/',
            isNew: true
        },
        {
            title: ['Address', '›', 'Edit'],
            path: '/:db/administration/address/:id/edit',
            saveRoute: '/api/address/',
            formRoute: '/api/address/',
            isNew: true
        },
        {
            title: ['Payment Term', '›', 'New'],
            path: '/:db/administration/payment-term/new',
            saveRoute: '/api/payment-term',
            formRoute: '/api/payment-term/',
            isNew: true
        },
        {
            title: ['Payment Term', '›', 'Edit'],
            path: '/:db/administration/payment-term/:id/edit',
            saveRoute: '/api/payment-term/',
            formRoute: '/api/payment-term/',
            isNew: true
        },
        {
            title: ['Region', '›', 'New'],
            path: '/:db/administration/region/new',
            saveRoute: '/api/region',
            formRoute: '/api/region/',
            isNew: true
        },
        {
            title: ['Region', '›', 'Edit'],
            path: '/:db/administration/region/:id/edit',
            saveRoute: '/api/region/',
            formRoute: '/api/region/',
            isNew: true
        },
        {
            title: ['Pricing Discount', '›', 'New'],
            path: '/:db/administration/pricing-discount/new',
            saveRoute: '/api/pricing-discount',
            formRoute: '/api/pricing-discount/',
            isNew: true
        },
        {
            title: ['Pricing Discount', '›', 'Edit'],
            path: '/:db/administration/pricing-discount/:id/edit',
            saveRoute: '/api/pricing-discount/',
            formRoute: '/api/pricing-discount/',
            isNew: true
        },
        {
            title: ['Pricing Pricelist', '›', 'New'],
            path: '/:db/administration/pricing-pricelist/new',
            saveRoute: '/api/pricing-pricelist',
            formRoute: '/api/pricing-pricelist/',
            isNew: true
        },
        {
            title: ['Pricing Pricelist', '›', 'Edit'],
            path: '/:db/administration/pricing-pricelist/:id/edit',
            saveRoute: '/api/pricing-pricelist/',
            formRoute: '/api/pricing-pricelist/',
            isNew: true
        },
        {
            title: ['Item Group', '›', 'New'],
            path: '/:db/administration/item-group/new',
            saveRoute: '/api/item-group',
            formRoute: '/api/item-group/',
            isNew: true
        },
        {
            title: ['Item Group', '›', 'Edit'],
            path: '/:db/administration/item-group/:id/edit',
            saveRoute: '/api/item-group/',
            formRoute: '/api/item-group/',
            isNew: true
        },
        {
            title: ['Pricing Group', '›', 'New'],
            path: '/:db/administration/pricing-group/new',
            saveRoute: '/api/pricing-group',
            formRoute: '/api/pricing-group/',
            isNew: true
        },
        {
            title: ['Pricing Group', '›', 'Edit'],
            path: '/:db/administration/pricing-group/:id/edit',
            saveRoute: '/api/pricing-group/',
            formRoute: '/api/pricing-group/',
            isNew: true
        },
        {
            title: ['Content', '›', 'New'],
            path: '/:db/cms-content/new',
            saveRoute: '/api/cms-contents',
            formRoute: '/api/cms-contents/',
            isNew: true
        },
        {
            title: ['Content', '›', 'Edit'],
            path: '/:db/cms-content/:id/edit',
            saveRoute: '/api/cms-contents/',
            formRoute: '/api/cms-contents/',
            isNew: true
        },
        {
            title: ['Content Route', '›', 'New'],
            path: '/:db/cms-content-route/new',
            saveRoute: '/api/cms-content-routes',
            formRoute: '/api/cms-content-routes/',
            isNew: true
        },
        {
            title: ['Content Route', '›', 'Edit'],
            path: '/:db/cms-content-route/:id/edit',
            saveRoute: '/api/cms-content-routes/',
            formRoute: '/api/cms-content-routes/',
            isNew: true
        },
        {
            title: ['Collection List', '›', 'New'],
            path: '/:db/cms-collection-list/new',
            saveRoute: '/api/cms-collection-lists',
            formRoute: '/api/cms-collection-lists/',
            isNew: true
        },
        {
            title: ['Collection List', '›', 'Edit'],
            path: '/:db/cms-collection-list/:id/edit',
            saveRoute: '/api/cms-collection-lists/',
            formRoute: '/api/cms-collection-lists/',
            isNew: true
        },
        {
            title: ['Collection Element', '›', 'New'],
            path: '/:db/cms-collection-element/new',
            saveRoute: '/api/cms-collection-element',
            formRoute: '/api/cms-collection-element/',
            isNew: true
        },
        {
            title: ['Collection Element', '›', 'Edit'],
            path: '/:db/cms-collection-element/:id/edit',
            saveRoute: '/api/cms-collection-element/',
            formRoute: '/api/cms-collection-element/',
            isNew: true
        },
        {
            title: ['Menu', '›', 'New'],
            path: '/:db/cms-menu/new',
            saveRoute: '/api/cms-menus',
            formRoute: '/api/cms-menus/',
            isNew: true
        },
        {
            title: ['Menu', '›', 'Edit'],
            path: '/:db/cms-menu/:id/edit',
            saveRoute: '/api/cms-menus/',
            formRoute: '/api/cms-menus/',
            isNew: true
        },
        {
            title: ['Attributes', '›', 'New'],
            path: '/:db/administration/attribute/new',
            saveRoute: '/api/attribute',
            formRoute: '/api/attribute/',
            isNew: true
        },
        {
            title: ['Attributes', '›', 'Edit'],
            path: '/:db/administration/attribute/:id/edit',
            saveRoute: '/api/attribute/',
            formRoute: '/api/attribute/',
            isNew: true
        },
        {
            title: ['Attributes Category', '›', 'New'],
            path: '/:db/administration/attribute-category/new',
            saveRoute: '/api/attribute-category',
            formRoute: '/api/attribute-category/',
            isNew: true
        },
        {
            title: ['Attributes Category', '›', 'Edit'],
            path: '/:db/administration/attribute-category/:id/edit',
            saveRoute: '/api/attribute-category/',
            formRoute: '/api/attribute-category/',
            isNew: true
        },
                {
            title: ['Attribute Group', '›', 'New'],
            path: '/:db/administration/attribute-group/new',
            saveRoute: '/api/attribute-group',
            formRoute: '/api/attribute-group/',
            isNew: true
        },
        {
            title: ['Attribute Group', '›', 'Edit'],
            path: '/:db/administration/attribute-group/:id/edit',
            saveRoute: '/api/attribute-group/',
            formRoute: '/api/attribute-group/',
            isNew: true
        },
        {
            title: ['Component', '›', 'New'],
            path: '/:db/cms-component/new',
            saveRoute: '/api/cms-components',
            formRoute: '/api/cms-components/',
            isNew: true
        },
        {
            title: ['Component', '›', 'Edit'],
            path: '/:db/cms-component/:id/edit',
            saveRoute: '/api/cms-components/',
            formRoute: '/api/cms-components/',
            isNew: true
        },
        {
            title: ['Project Board', '›', 'New'],
            path: '/:db/project-boards/new',
            saveRoute: '/api/project-board',
            formRoute: '/api/project-board/',
            isNew: true
        },
        {
            title: ['Project Board', '›', 'Edit'],
            path: '/:db/project-boards/:id/edit',
            saveRoute: '/api/project-board/',
            formRoute: '/api/project-board/',
            isNew: true
        },
        {
            title: ['Warranty', '›', 'New'],
            path: '/:db/warranties/new',
            saveRoute: '/api/warranties',
            formRoute: '/api/warranties/',
            isNew: true
        },
        {
            title: ['Warranty', '›', 'Edit'],
            path: '/:db/warranties/:id/edit',
            saveRoute: '/api/warranties/',
            formRoute: '/api/warranties/',
            isNew: true
        },
        {
            title: ['Conract', '›', 'New'],
            path: '/:db/contracts/new',
            saveRoute: '/api/contracts',
            formRoute: '/api/contracts/',
            isNew: true
        },
        {
            title: ['Conract', '›', 'Edit'],
            path: '/:db/contracts/:id/edit',
            saveRoute: '/api/contracts/',
            formRoute: '/api/contracts/',
            isNew: true
        },
    ];
}
