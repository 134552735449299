import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {plainTranslate} from "../common/translate";
import { withRouter } from 'react-router-dom'
import { Drawer, Button, Menu } from 'antd';
import {connect} from 'react-redux';
//import 'antd/dist/antd.css';
import {
    onSidebarMount,
} from '../../actions/actions';

const { SubMenu } = Menu;



class MenuMobile extends Component {
      constructor(props) {
      super(props);
      this.state = {
        visible: false,
        placement: 'left',
        openKeys: []
      };
      this.showDrawer = this.showDrawer.bind(this);
      this.onClose = this.onClose.bind(this);
      this.onOpenChange = this.onOpenChange.bind(this);
      this.onIconClick = this.onIconClick.bind(this);
      this.onTextClick = this.onTextClick.bind(this);
  }
  rootSubmenuKeys = ['sub1', 'sub2', 'sub3','sub12'];

  showDrawer(){
    this.setState({
      visible: true,
    });
  }

  onClose(){
    this.setState({
      visible: false,openKeys: []
    });
  }

  onIconClick(url){
    this.props.history.push(url);
    this.onClose();
  }

  onTextClick(url){
    this.props.history.push(url);
    this.onClose();
  }

  componentDidMount() {
      this.props.onSidebarMount('/'+this.props.db+'/api/settings/sidebar');
      require('antd/dist/antd.css');
  }

  onOpenChange(openKeys){
    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  render() {
    let showReportsTab = false;
    if(this.props.settings.bundles.reportsbundle && this.props.settings.reports && this.props.settings.reports.predefinedReports){
      this.props.settings.reports.predefinedReports.map((report, key) => {
        if(report.id === 'stockOnHand' || report.id === 'payments'){
          showReportsTab=true;
        }
      });
    }

    const urlParams = new URLSearchParams(this.props.location.search);
    const locId = urlParams.get('locId');
    const formId = urlParams.get('type');

    if(locId !== 'undefined' && locId !== null){
      localStorage.setItem('locId', locId);
    }

    if(formId !== 'undefined' && formId !== null){
      localStorage.setItem('formid', formId);
    }

    let saleLink = '/' + this.props.match.params.db + '/pos/transactions/new?locId='+localStorage.locId;

    if(localStorage.formid !== 'undefined'){
      saleLink = saleLink+'&type='+localStorage.formid;
    }

    const settings = this.props.settings;

    return (
           <div className="left-mobileMenu">
              
              <i className="fa fa-bars" aria-hidden="true" onClick={this.showDrawer}/>
              
              <Drawer
                title={<span>
                    <span>{plainTranslate(settings.locale, 'Menu')}</span>
                    <i onClick={this.onClose} style={{float: "right"}} className="fa fa-close" aria-hidden="true"></i>
                </span>}
                placement={this.state.placement}
                closable={false}
                onClose={this.onClose}
                visible={this.state.visible}
                bodyStyle={{ padding: 0 }}
              >
                <Menu
                  //onClick={this.handleClick}
                  //style={{ width: 256 }}
                  //openKeys={this.state.openKeys}
                  openKeys={this.state.openKeys}
                  onOpenChange={this.onOpenChange}
                  mode="inline"
                  className="mobileMenu"
                  selectedKeys={[]}
                >
                    <Menu.Item key="1">
                      <Link onClick={this.onClose} className="draggable" id="dashboard" to={'/' + this.props.db + '/dashboard'} title={plainTranslate(settings.locale, 'Dashboard')}>
                        <span>{plainTranslate(settings.locale, 'Dashboard')}</span>
                    </Link>
                    </Menu.Item>
                    <SubMenu key="sub1" title={<span>{plainTranslate(settings.locale, 'Accounts')}</span>}>
                        {
                          settings.lists && settings.lists.map((list, key) => {
                              return <Menu.Item key={list.id}>
                                <Link onClick={this.onClose} className="draggable" id={list.label}
                                       title={plainTranslate(settings.locale, list.label)}
                                       to={'/' + this.props.db + '/accounts-v2/' + list.id}>
                                        <span>{plainTranslate(settings.locale, list.label)}</span>
                                    </Link>
                              </Menu.Item>
                          })
                        } 
                    </SubMenu>
                    {settings.bundles.dealbundle && settings.plan.deals &&
                        <Menu.Item key="2">
                            <Link onClick={this.onClose} className="draggable" id="Deals"
                               to={'/' + this.props.db + '/deals-v2'}
                               title={plainTranslate(settings.locale, 'Deals')}>
                                <span>{plainTranslate(settings.locale, 'Deals')}</span>
                            </Link>
                        </Menu.Item>
                    }
                    {settings.bundles.salesbundle && settings.plan.sales &&
                        <Menu.Item key="3">
                            <Link onClick={this.onClose} className="draggable" id="Sales"
                                   to={'/' + this.props.db + '/sales-v2'}
                                   title={plainTranslate(settings.locale, 'Sales')}>
                                    <span>{plainTranslate(settings.locale, 'Sales')}</span>
                            </Link>
                        </Menu.Item>
                    }
                    {settings.bundles.expensebundle && settings.plan.expense &&
                      <Menu.Item key="5">
                        <Link onClick={this.onClose} id="expense"
                           to={'/' + this.props.db + '/expenses-v2'}
                           title={plainTranslate(settings.locale, 'Expenses')}>
                            <span>{plainTranslate(settings.locale, 'Expenses')}</span>
                        </Link>
                      </Menu.Item>
                    }
                    {settings.bundles.paymentbundle && settings.plan.payments &&
                      <Menu.Item key="6">
                        <Link onClick={this.onClose} id="payments"
                           to={'/' + this.props.db + '/payments-v2'}
                           title={plainTranslate(settings.locale, 'Payments')}>
                            <span>{plainTranslate(settings.locale, 'Payments')}</span>
                        </Link>
                      </Menu.Item>
                    }
                    {settings.bundles.inventorybundle && settings.plan.inventory &&
                      <Menu.Item key="7">
                        <Link onClick={this.onClose} id="inventory"
                           to={'/' + this.props.db + '/inventory-v2'}
                           title={plainTranslate(settings.locale, 'Inventory')}>
                            <span>{plainTranslate(settings.locale, 'Inventory')}</span>
                        </Link>
                      </Menu.Item>
                    }
                    {settings.bundles.accountingbundle && settings.plan.accounting &&
                      <Menu.Item key="8">
                        <Link onClick={this.onClose} id="accounting"
                           to={'/' + this.props.db + '/accounting/ledger'}
                           title={plainTranslate(settings.locale, 'Accounting')}>
                            <span>{plainTranslate(settings.locale, 'Accounting')}</span>
                        </Link>
                      </Menu.Item>
                    }
                    {
                      settings.bundles.hrbundle &&
                      <Menu.Item key="9">
                        <Link onClick={this.onClose} id="personnel"
                           to={'/' + settings.db + '/hr/employees'}
                           title={plainTranslate(settings.locale, 'Personnel')}>
                            <span>{plainTranslate(settings.locale, 'Personnel')}</span>
                        </Link>
                      </Menu.Item>
                    }
                    {
                      settings.plan.project &&
                      <SubMenu key="sub2" title={<span>{plainTranslate(settings.locale, 'Projects and Tasks')}</span>}>
                        <Menu.Item key="sub2-1">
                          <Link onClick={this.onClose} className="draggable"id="projects"
                                 title={plainTranslate(settings.locale, 'Projects')}
                                 to={'/' + this.props.db + '/projects-v2'}>
                                  <span>{plainTranslate(settings.locale, 'Projects')}</span>
                              </Link>
                        </Menu.Item>
                        <Menu.Item key="sub2-2">
                          <Link onClick={this.onClose} className="draggable"id="tasks"
                                 title={plainTranslate(settings.locale, 'Tasks')}
                                 to={'/' + this.props.db + '/tasks-v2'}>
                                  <span>{plainTranslate(settings.locale, 'Tasks')}</span>
                              </Link>
                        </Menu.Item>
                        <Menu.Item key="sub2-3">
                          <Link onClick={this.onClose} className="draggable"id="logged-time"
                                 title={plainTranslate(settings.locale, 'Logged Time')}
                                 to={'/' + this.props.db + '/log-time-v2'}>
                                  <span>{plainTranslate(settings.locale, 'Logged Time')}</span>
                              </Link>
                        </Menu.Item>
                      </SubMenu>
                    }
                    {settings.bundles.purchasebundle && settings.plan.purchasing &&
                      <SubMenu key="sub3" title={<span>{plainTranslate(settings.locale, 'Item requests')}</span>}>
                        <Menu.Item key="sub3-2">
                          <Link onClick={this.onClose} className="draggable"id="requests"
                                 title={plainTranslate(settings.locale, 'Requests')}
                                 to={'/' + this.props.db + '/inventory-requests'}>
                                  <span>{plainTranslate(settings.locale, 'Requests')}</span>
                              </Link>
                        </Menu.Item>
                        <Menu.Item key="sub3-3">
                          <Link onClick={this.onClose} className="draggable"id="requests-new"
                                 title={plainTranslate(settings.locale, 'Request-new')}
                                 to={'/' + this.props.db + '/inventory-request-v2'}>
                                  <span>{plainTranslate(settings.locale, 'Request-new')}</span>
                              </Link>
                        </Menu.Item>
                        <Menu.Item key="sub3-4">
                          <Link onClick={this.onClose} className="draggable"id="request-details"
                                 title={plainTranslate(settings.locale, 'Requests details')}
                                 to={'/' + this.props.db + '/inventory-request/details'}>
                                  <span>{plainTranslate(settings.locale, 'Requests details')}</span>
                              </Link>
                        </Menu.Item>
                      </SubMenu>
                    }
                    {settings.bundles.productionbundle && settings.plan.production &&
                      <Menu.Item key="12">
                        <Link onClick={this.onClose} className="draggable" id="production"
                           to={'/' + this.props.db + '/production-v2'}
                           title={plainTranslate(settings.locale, 'Production')}>
                            <span>{plainTranslate(settings.locale, 'Production')}</span>
                        </Link>
                      </Menu.Item>
                    }
                    {settings.bundles.dmsbundle && settings.plan.documents &&
                      <Menu.Item key="13">
                        <Link onClick={this.onClose} id="documents"
                           to={'/' + this.props.db + '/storage'}
                           title={plainTranslate(settings.locale, 'Documents')}>
                            <span>{plainTranslate(settings.locale, 'Documents')}</span>
                        </Link>
                      </Menu.Item>
                    }
                    {showReportsTab &&
                      <SubMenu
                        key="sub2"
                        title={<span>{plainTranslate(settings.locale, 'Reports')}</span>}>
                            <SubMenu key="sub13" title={<span>{plainTranslate(settings.locale, 'My reports')}</span>}>
                                {this.props.settings.bundles.reportsbundle && this.props.settings.reports && this.props.settings.reports.userReports &&
                                    this.props.settings.reports.userReports.map((report, key) => {
                                        return (<Menu.Item key={report.id} style={{display: "flex"}}>
                                            {report.isPrintable &&
                                              <i onClick={() => this.onIconClick('/' + this.props.settings.db + '/data-source/print/' + report.id+"/"+report.params)} className="fa fa-print" style={{marginRight: 20}}></i>
                                            }
                                            <span onClick={() => this.onTextClick('/' + this.props.settings.db + '/data-source/' + report.id+"?type="+report.params)} style={{whiteSpace: "nowrap",width: 320,overflow: "hidden",textOverflow: "ellipsis"}}>{plainTranslate(this.props.settings.locale, report.label)}</span>
                                        </Menu.Item>)
                                    })
                                }
                            </SubMenu>
                            <SubMenu key="sub14" title={<span>{plainTranslate(settings.locale, 'Predefined')}</span>}>
                                {this.props.settings.bundles.reportsbundle && this.props.settings.reports && this.props.settings.reports.predefinedReports &&
                                this.props.settings.reports.predefinedReports.map((report, key) => {
                                        return (<Menu.Item key={report.id} style={{display: "flex"}}>
                                            {report.id === 'pivoting' &&
                                                <Link onClick={this.onClose} id={report.label} className="draggable"
                                                      title={plainTranslate(this.props.settings.locale, "Pivot Analysis")}
                                                      to={'/' + this.props.settings.db + '/pivoting'}>
                                                        <span style={{whiteSpace: "nowrap",width: 320,overflow: "hidden",textOverflow: "ellipsis"}}>{plainTranslate(this.props.settings.locale, report.label)}</span>
                                                </Link>
                                            }
                                            {report.id === 'stockOnHand' &&
                                                <Link onClick={this.onClose} id={report.label} className="draggable"
                                                      title={plainTranslate(this.props.settings.locale, "Stock on hand")}
                                                      to={'/' + this.props.settings.db + '/data-source/stock-on-hand'}>
                                                        <span style={{whiteSpace: "nowrap",width: 320,overflow: "hidden",textOverflow: "ellipsis"}}>{plainTranslate(this.props.settings.locale, report.label)}</span>
                                                </Link>
                                            }
                                            {report.id === 'saleRevenue' &&
                                                <Link onClick={this.onClose} id={report.label} className="draggable"
                                                      title={plainTranslate(this.props.settings.locale, "Sale Revenue")}
                                                      to={'/' + this.props.settings.db + '/data-source/sale-revenue'}>
                                                        <span style={{whiteSpace: "nowrap",width: 320,overflow: "hidden",textOverflow: "ellipsis"}}>{plainTranslate(this.props.settings.locale, report.label)}</span>
                                                </Link>
                                            }
                                            {report.id === 'accountReceivables' &&
                                                <Link onClick={this.onClose} id={report.label} className="draggable"
                                                      title={plainTranslate(this.props.settings.locale, "Account Receivables")}
                                                      to={'/' + this.props.settings.db + '/data-source/account-receivables'}>
                                                        <span style={{whiteSpace: "nowrap",width: 320,overflow: "hidden",textOverflow: "ellipsis"}}>{plainTranslate(this.props.settings.locale, report.label)}</span>
                                                </Link>
                                            }
                                            {report.id === 'accountPayables' &&
                                                <Link onClick={this.onClose} id={report.label} className="draggable"
                                                      title={plainTranslate(this.props.settings.locale, "Account Payables")}
                                                      to={'/' + this.props.settings.db + '/data-source/account-payables'}>
                                                        <span style={{whiteSpace: "nowrap",width: 320,overflow: "hidden",textOverflow: "ellipsis"}}>{plainTranslate(this.props.settings.locale, report.label)}</span>
                                                </Link>
                                            }
                                            {report.id === 'profitAndLoss' &&
                                                <Link onClick={this.onClose} id={report.label} className="draggable"
                                                      title={plainTranslate(this.props.settings.locale, "Profit and Loss")}
                                                      to={'/' + this.props.settings.db + '/data-source/profit-loss'}>
                                                        <span style={{whiteSpace: "nowrap",width: 320,overflow: "hidden",textOverflow: "ellipsis"}}>{plainTranslate(this.props.settings.locale, report.label)}</span>
                                                </Link>
                                            }
                                            {report.id === 'reservedQuantity' &&
                                                <Link onClick={this.onClose} id={report.label} className="draggable"
                                                      title={plainTranslate(this.props.settings.locale, "Reserved Quantity")}
                                                      to={'/' + this.props.settings.db + '/data-source/reserved-quantity'}>
                                                        <span style={{whiteSpace: "nowrap",width: 320,overflow: "hidden",textOverflow: "ellipsis"}}>{plainTranslate(this.props.settings.locale, report.label)}</span>
                                                </Link>
                                            }
                                            {report.id === 'agingAnalysis' &&
                                                <Link onClick={this.onClose} id={report.label} className="draggable"
                                                      title={plainTranslate(this.props.settings.locale, "Aging Analysis")}
                                                      to={'/' + this.props.settings.db + '/data-source/aging-analysis'}>
                                                        <span style={{whiteSpace: "nowrap",width: 320,overflow: "hidden",textOverflow: "ellipsis"}}>{plainTranslate(this.props.settings.locale, report.label)}</span>
                                                </Link>
                                            }
                                            {report.id === 'paymentBalance' &&
                                                <Link onClick={this.onClose} id={report.label} className="draggable"
                                                      title={plainTranslate(this.props.settings.locale, "Payment Balance")}
                                                      to={'/' + this.props.settings.db + '/data-source/payment-balance'}>
                                                        <span style={{whiteSpace: "nowrap",width: 320,overflow: "hidden",textOverflow: "ellipsis"}}>{plainTranslate(this.props.settings.locale, report.label)}</span>
                                                </Link>
                                            }
                                            {report.id === 'payments' &&
                                                <Link onClick={this.onClose} id={report.label} className="draggable"
                                                      title={plainTranslate(this.props.settings.locale, "Payments Report")}
                                                      to={'/' + this.props.settings.db + '/report/payments/filters'}>
                                                        <span style={{whiteSpace: "nowrap",width: 320,overflow: "hidden",textOverflow: "ellipsis"}}>{plainTranslate(this.props.settings.locale, report.label)}</span>
                                                </Link>
                                            }
                                            {report.id === 'inventoryMovement' &&
                                                <Link onClick={this.onClose} id={report.label} className="draggable"
                                                      title={plainTranslate(this.props.settings.locale, "Inventory Movement")}
                                                      to={'/' + this.props.settings.db + '/data-source/inventory-movement'}>
                                                        <span style={{whiteSpace: "nowrap",width: 320,overflow: "hidden",textOverflow: "ellipsis"}}>{plainTranslate(this.props.settings.locale, report.label)}</span>
                                                </Link>
                                            }
                                            {report.id === 'suppliers' &&
                                                <Link onClick={this.onClose} id={report.label} className="draggable"
                                                      title={plainTranslate(this.props.settings.locale, "Suppliers")}
                                                      to={'/' + this.props.settings.db + '/data-source/suppliers'}>
                                                        <span style={{whiteSpace: "nowrap",width: 320,overflow: "hidden",textOverflow: "ellipsis"}}>{plainTranslate(this.props.settings.locale, report.label)}</span>
                                                </Link>
                                            }
                                            {report.id === 'inventoryTree' &&
                                                <Link onClick={this.onClose} id={report.label} className="draggable"
                                                      title={plainTranslate(this.props.settings.locale, "Inventory Tree")}
                                                      to={'/' + this.props.settings.db + '/items-tree'}>
                                                        <span style={{whiteSpace: "nowrap",width: 320,overflow: "hidden",textOverflow: "ellipsis"}}>{plainTranslate(this.props.settings.locale, report.label)}</span>
                                                </Link>
                                            }
                                        </Menu.Item>)
                                    })
                                }
                            </SubMenu>
                            <SubMenu key="sub15" title={<span>{plainTranslate(settings.locale, 'Custom')}</span>}>
                                {this.props.settings.bundles.reportsbundle && this.props.settings.reports && this.props.settings.reports.customReports &&
                                this.props.settings.reports.customReports.map((report, key) => {
                                        return (<Menu.Item key={report.id} style={{display: "flex"}}>
                                            <Link onClick={this.onClose} id={report.label} className="draggable"
                                                  title={plainTranslate(this.props.settings.locale, report.label)}
                                                  to={'/' + this.props.settings.db + '/report/' + report.id}>
                                                    <span style={{whiteSpace: "nowrap",width: 320,overflow: "hidden",textOverflow: "ellipsis"}}>{plainTranslate(this.props.settings.locale, report.label)}</span>
                                            </Link>

                                        </Menu.Item>)
                                    })
                                }
                            </SubMenu>
                      </SubMenu>
                    }
                    {settings.bundles && settings.bundles.administration &&
                        <SubMenu key="sub3" title={<span>{plainTranslate(settings.locale, 'Administration')}</span>}>
                            {settings.bundles && settings.bundles.appanalysisbundle && settings.administration && settings.administration.includes('analysis') &&
                                <SubMenu key="sub4" title={<span>{plainTranslate(settings.locale, 'Analysis')}</span>}>
                                    <Menu.Item key="14">
                                        <Link onClick={this.onClose} className="draggable" id="codes"
                                           to={'/' + this.props.db + '/administration/analysis-codes'}
                                           title={plainTranslate(settings.locale, 'Codes')}>
                                            <span>{plainTranslate(settings.locale, 'Codes')}</span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="15">
                                        <Link onClick={this.onClose} className="draggable" id="categories"
                                           to={'/' + this.props.db + '/administration/analysis-categories'}
                                           title={plainTranslate(settings.locale, 'Categories')}>
                                            <span>{plainTranslate(settings.locale, 'Categories')}</span>
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>
                            }
                            {settings.administration && settings.administration.includes('currencies') &&
                                <SubMenu key="sub5" title={<span>{plainTranslate(settings.locale, 'Currencies')}</span>}>
                                    <Menu.Item key="16">
                                        <Link onClick={this.onClose} className="draggable" id="codes"
                                           to={'/' + this.props.db + '/administration/currencies-v2'}
                                           title={plainTranslate(settings.locale, 'Currencies')}>
                                            <span>{plainTranslate(settings.locale, 'Currencies')}</span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="17">
                                        <Link onClick={this.onClose} className="draggable" id="categories"
                                           to={'/' + this.props.db + '/administration/currency-rates-v2'}
                                           title={plainTranslate(settings.locale, 'Currency Rates')}>
                                            <span>{plainTranslate(settings.locale, 'Currency Rates')}</span>
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>
                            }
                            {settings.bundles &&  settings.bundles.appactivitiesbundle && settings.administration && settings.administration.includes('events') &&
                                <Menu.Item key="18">
                                    <Link onClick={this.onClose} className="draggable" id="events"
                                       to={'/' + this.props.db + '/administration/events-v2'}
                                       title={plainTranslate(settings.locale, 'Events')}>
                                        <span>{plainTranslate(settings.locale, 'Events')}</span>
                                    </Link>
                                </Menu.Item>
                            }
                            {settings.administration && settings.administration.includes('taxes') &&
                                <Menu.Item key="19">
                                    <Link onClick={this.onClose} className="draggable" id="taxes"
                                       to={'/' + this.props.db + '/administration/taxes-v2'}
                                       title={plainTranslate(settings.locale, 'Taxes')}>
                                        <span>{plainTranslate(settings.locale, 'Taxes')}</span>
                                    </Link>
                                </Menu.Item>
                            }
                            {settings.administration && settings.administration.includes('items') &&
                                <SubMenu key="sub6" title={<span>{plainTranslate(settings.locale, 'Items')}</span>}>
                                    <Menu.Item key="20">
                                        <Link onClick={this.onClose} className="draggable" id="items"
                                           to={'/' + this.props.db + '/administration/items-v2'}
                                           title={plainTranslate(settings.locale, 'Items')}>
                                            <span>{plainTranslate(settings.locale, 'Items')}</span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="21">
                                        <Link onClick={this.onClose} className="draggable" id="categories"
                                           to={'/' + this.props.db + '/administration/items/categories-v2'}
                                           title={plainTranslate(settings.locale, 'Categories')}>
                                            <span>{plainTranslate(settings.locale, 'Categories')}</span>
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>
                            }
                            {settings.administration && settings.administration.includes('measures') &&
                                <SubMenu key="sub7" title={<span>{plainTranslate(settings.locale, 'Measures')}</span>}>
                                    <Menu.Item key="22">
                                        <Link onClick={this.onClose} className="draggable" id="measures"
                                           to={'/' + this.props.db + '/administration/measure-v2'}
                                           title={plainTranslate(settings.locale, 'Measures')}>
                                            <span>{plainTranslate(settings.locale, 'Measures')}</span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="23">
                                        <Link onClick={this.onClose} className="draggable" id="measure-conversion"
                                           to={'/' + this.props.db + '/administration/measure-conversion-v2'}
                                           title={plainTranslate(settings.locale, 'Measure conversions')}>
                                            <span>{plainTranslate(settings.locale, 'Measure conversions')}</span>
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>
                            }
                            {settings.bundles &&  settings.bundles.appbillingdatabundle && settings.administration && settings.administration.includes('customers') &&
                                <Menu.Item key="24">
                                    <Link onClick={this.onClose} className="draggable" id="billing-data"
                                       to={'/' + this.props.db + '/administration/billing-data-v2'}
                                       title={plainTranslate(settings.locale, 'Billing Data')}>
                                        <span>{plainTranslate(settings.locale, 'Billing Data')}</span>
                                    </Link>
                                </Menu.Item>
                            }
                            {settings.bundles &&  settings.bundles.applocationbundle && settings.administration && settings.administration.includes('location') &&
                                <Menu.Item key="25">
                                    <Link onClick={this.onClose} className="draggable" id="locations"
                                       to={'/' + this.props.db + '/administration/locations-v2'}
                                       title={plainTranslate(settings.locale, 'Locations')}>
                                        <span>{plainTranslate(settings.locale, 'Locations')}</span>
                                    </Link>
                                </Menu.Item>
                            }
                            {settings.administration && settings.administration.includes('paymentMethods') &&
                                <Menu.Item key="26">
                                    <Link onClick={this.onClose} className="draggable" id="taxes"
                                       to={'/' + this.props.db + '/administration/payment-methods-v2'}
                                       title={plainTranslate(settings.locale, 'Payment methods')}>
                                        <span>{plainTranslate(settings.locale, 'Payment methods')}</span>
                                    </Link>
                                </Menu.Item>
                            }
                            {settings.bundles.apptemplatesbundle && settings.administration.includes('templates') &&
                                <SubMenu key="sub8" title={<span>{plainTranslate(settings.locale, 'Templates')}</span>}>
                                    <Menu.Item key="27">
                                        <Link onClick={this.onClose} className="draggable" id="documents"
                                           to={'/' + this.props.db + '/administration/templates/documents-v2'}
                                           title={plainTranslate(settings.locale, 'Documents')}>
                                            <span>{plainTranslate(settings.locale, 'Documents')}</span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="28">
                                        <Link onClick={this.onClose} className="draggable" id="emails"
                                           to={'/' + this.props.db + '/administration/templates/email-v2'}
                                           title={plainTranslate(settings.locale, 'Emails')}>
                                            <span>{plainTranslate(settings.locale, 'Emails')}</span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="29">
                                        <Link onClick={this.onClose} className="draggable" id="sms"
                                           to={'/' + this.props.db + '/administration/templates/sms-v2'}
                                           title={plainTranslate(settings.locale, 'SMS')}>
                                            <span>{plainTranslate(settings.locale, 'SMS')}</span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="30">
                                        <Link onClick={this.onClose} className="draggable" id="exports"
                                           to={'/' + this.props.db + '/administration/templates/export-v2'}
                                           title={plainTranslate(settings.locale, 'Exports')}>
                                            <span>{plainTranslate(settings.locale, 'Exports')}</span>
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>
                            }
                            {settings.administration.includes('localization') &&
                                <SubMenu key="sub9" title={<span>{plainTranslate(settings.locale, 'Location')}</span>}>
                                    <Menu.Item key="31">
                                        <Link onClick={this.onClose} className="draggable" id="languages"
                                           to={'/' + this.props.db + '/administration/languages-v2'}
                                           title={plainTranslate(settings.locale, 'Languages')}>
                                            <span>{plainTranslate(settings.locale, 'Languages')}</span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="32">
                                        <Link onClick={this.onClose} className="draggable" id="translations"
                                           to={'/' + this.props.db + '/administration/translations-v2'}
                                           title={plainTranslate(settings.locale, 'Translations')}>
                                            <span>{plainTranslate(settings.locale, 'Translations')}</span>
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>
                            }
                            {settings.bundles && settings.user && settings.bundles.appuserbundle && settings.roles.includes("ROLE_SUPER_ADMIN") && 
                              <SubMenu key="sub10" title={<span>{plainTranslate(settings.locale, 'Users')}</span>}>
                                <Menu.Item key="443">
                                  <Link onClick={this.onClose} className="draggable" id="users"
                                    to={'/' + this.props.db + '/administration/users-v2/admin'}
                                    title={plainTranslate(settings.locale, 'Users')}>
                                    <span>{plainTranslate(settings.locale, 'Users')}</span>
                                  </Link>
                                </Menu.Item>
                                <Menu.Item key="444">
                                  <Link onClick={this.onClose} className="draggable" id="users"
                                    to={'/' + this.props.db + '/administration/users-v2/portal'}
                                    title={plainTranslate(settings.locale, 'Portal Users')}>
                                    <span>{plainTranslate(settings.locale, 'Portal Users')}</span>
                                  </Link>
                                </Menu.Item>
                                <Menu.Item key="445">
                                  <Link onClick={this.onClose} className="draggable" id="users"
                                    to={'/' + this.props.db + '/administration/users-v2/online'}
                                    title={plainTranslate(settings.locale, 'Online Users')}>
                                    <span>{plainTranslate(settings.locale, 'Online Users')}</span>
                                  </Link>
                                </Menu.Item>
                                <Menu.Item key="446">
                                  <Link onClick={this.onClose} className="draggable" id="users"
                                    to={'/' + this.props.db + '/settings/roles'}
                                    title={plainTranslate(settings.locale, 'Roles')}>
                                    <span>{plainTranslate(settings.locale, 'Roles')}</span>
                                  </Link>
                                </Menu.Item>
                              </SubMenu>
                            } 
                            {settings.bundles.apicrmbundle && settings.administration.includes('pricing') &&
                                <SubMenu key="sub10" title={<span>{plainTranslate(settings.locale, 'Pricing')}</span>}>
                                    <Menu.Item key="33">
                                        <Link onClick={this.onClose} className="draggable" id="price-lists"
                                           to={'/' + this.props.db + '/administration/pricelists-v2'}
                                           title={plainTranslate(settings.locale, 'Price Lists')}>
                                            <span>{plainTranslate(settings.locale, 'Price Lists')}</span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="34">
                                        <Link onClick={this.onClose} className="draggable" id="discounts"
                                           to={'/' + this.props.db + '/administration/discounts-v2'}
                                           title={plainTranslate(settings.locale, 'Discounts')}>
                                            <span>{plainTranslate(settings.locale, 'Discounts')}</span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="35">
                                        <Link onClick={this.onClose} className="draggable" id="groups"
                                           to={'/' + this.props.db + '/administration/groups-v2'}
                                           title={plainTranslate(settings.locale, 'Account Groups')}>
                                            <span>{plainTranslate(settings.locale, 'Account Groups')}</span>
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>
                            }
                            {settings.administration.includes('other') &&
                                <SubMenu key="sub11" title={<span>{plainTranslate(settings.locale, 'Other')}</span>}>
                                    <Menu.Item key="36">
                                        <Link onClick={this.onClose} className="draggable" id="measures"
                                           to={'/' + this.props.db + '/administration/texts-v2'}
                                           title={plainTranslate(settings.locale, 'Text')}>
                                            <span>{plainTranslate(settings.locale, 'Text')}</span>
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>
                            }
                            {settings.bundles.syssetupbundle && settings.roles.includes('ROLE_SUPER_ADMIN') &&
                                <Menu.Item key="37">
                                    <Link onClick={this.onClose} className="draggable" id="open-period"
                                       to={'/' + this.props.db + '/settings/general'}
                                       title={plainTranslate(settings.locale, 'Open period')}>
                                        <span>{plainTranslate(settings.locale, 'Open period')}</span>
                                    </Link>
                                </Menu.Item>
                            }
                        </SubMenu>
                    }
                    <SubMenu key="sub12" title={<span>{plainTranslate(settings.locale, 'My profile')}</span>}>
                        {
                          settings.user &&
                            <Menu.Item key="38">
                                <Link onClick={this.onClose} className="draggable" id="profile"
                                   to={'/' + this.props.db + '/user/profile'}
                                   title={plainTranslate(settings.locale, 'Profile')}>
                                    <span>{plainTranslate(settings.locale, 'Profile')}</span>
                                </Link>
                            </Menu.Item>
                        }
                        {
                          settings.user && settings.roles.includes("ROLE_SUPER_ADMIN") &&
                            <Menu.Item key="39">
                                <Link onClick={this.onClose} className="draggable" id="subscription"
                                   to={'/' + this.props.db + '/subscription-details'}
                                   title={plainTranslate(settings.locale, 'Subscription and Billing')}>
                                    <span>{plainTranslate(settings.locale, 'Subscription and Billing')}</span>
                                </Link>
                            </Menu.Item>
                        } 
                        {
                          settings.user && settings.roles.includes("ROLE_SUPER_ADMIN") &&
                            <Menu.Item key="40">
                                <Link onClick={this.onClose} className="draggable" id="modules"
                                   to={'/' + this.props.db + '/modules'}
                                   title={plainTranslate(settings.locale, 'Modules and Add-ons')}>
                                    <span>{plainTranslate(settings.locale, 'Modules and Add-ons')}</span>
                                </Link>
                            </Menu.Item>
                        } 
                        {
                          settings.user && settings.roles.includes("ROLE_SUPER_ADMIN") &&
                            <Menu.Item key="41">
                                <Link onClick={this.onClose} className="draggable" id="settings-general"
                                   to={'/' + this.props.db + '/settings/general'}
                                   title={plainTranslate(settings.locale, 'Settings')}>
                                    <span>{plainTranslate(settings.locale, 'Settings')}</span>
                                </Link>
                            </Menu.Item>
                        } 
                        {
                          settings.user && settings.roles.includes("ROLE_SUPER_ADMIN") &&
                            <Menu.Item key="42">
                                <Link onClick={this.onClose} className="draggable" id="stats"
                                   to={'/' + this.props.db + '/stats'}
                                   title={plainTranslate(settings.locale, 'Statistics')}>
                                    <span>{plainTranslate(settings.locale, 'Statistics')}</span>
                                </Link>
                            </Menu.Item>
                        } 
                        {
                          settings.bundles && settings.user && settings.bundles.appuserbundle && settings.roles.includes("ROLE_SUPER_ADMIN") &&
                            <Menu.Item key="43">
                                <Link onClick={this.onClose} className="draggable" id="users"
                                   to={'/' + this.props.db + '/administration/users-v2/admin'}
                                   title={plainTranslate(settings.locale, 'Users')}>
                                    <span>{plainTranslate(settings.locale, 'Users')}</span>
                                </Link>
                            </Menu.Item>
                        } 
                        {
                          settings.user &&
                            <Menu.Item key="44">
                                <Link onClick={this.onClose} className="draggable" id="logout"
                                   to={'/' + this.props.db + '/logout'}
                                   title={plainTranslate(settings.locale, 'Log Out')}>
                                    <span>{plainTranslate(settings.locale, 'Log Out')}</span>
                                </Link>
                            </Menu.Item>
                        } 
                        {
                          settings.user &&
                            <Menu.Item key="45">
                                <Link onClick={this.onClose} className="draggable" id="workspace-signin"
                                   to={'/' + this.props.db + '/workspace/signin'}
                                   title={plainTranslate(settings.locale, 'Sign in to another workspace')}>
                                    <span>{plainTranslate(settings.locale, 'Sign in to another workspace')}</span>
                                </Link>
                            </Menu.Item>
                        } 
                        {
                          !settings.user &&
                            <Menu.Item key="46">
                                <Link onClick={this.onClose} className="draggable" id="user-signin"
                                   to={'/' + this.props.db + '/user/signin'}
                                   title={plainTranslate(settings.locale, 'Sign in')}>
                                    <span>{plainTranslate(settings.locale, 'Sign in')}</span>
                                </Link>
                            </Menu.Item>
                        } 
                    </SubMenu>
                </Menu>
              </Drawer>
            </div>
    );
  }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({
  onSidebarMount: (route) => dispatch(onSidebarMount(route)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MenuMobile));